import { BehaviorSubject, Observable, Subject, of, timer } from 'rxjs';
import { Guid, NOTIFICATION_TYPE, sendNotification } from '../api/AmcApi';
import { switchMap, takeUntil } from 'rxjs/operators';

import { BaseAuthInfo } from '../model/auth/BaseAuthInfo';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { StorageService } from 'src/app/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  samlPollTimeoutSeconds: number;
  private API_URL;
  private API_VERSION = 'v1';
  private agentUrl: string;
  private loginAuthInfos$: BehaviorSubject<BaseAuthInfo[]> = new BehaviorSubject([]);
  private samlPollIntervalMs: number;
  private stopPolling$ = new Subject<void>();

  private userName = '';

  // Tracks whether the background timer is active or no
  // If is active, we dont want to spawn more timers to renew access token
  backgroundTokenRenewTimer = null;

  // Controls whether the renew operation should be stopped
  // If stopped, timeout should be cleared, and any access_tokens should be removed
  cancelRenew = false;

  latestTokenIssue: number = null;
  latestTokenExpiration: number = null;
  backgroundRetryAttemptNumber = 0;
  maxRetries = 3;
  retryDelay = 1000;

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private configService: ConfigurationService,
    private _storageService: StorageService,
    private ps: NgxPermissionsService
  ) {}

  initialize(): void {
    this.API_URL = this.configService.config.apiUrl;
    this.API_VERSION = this.configService.config.apiVersion;
    this.agentUrl = window.location.origin;
    this.samlPollTimeoutSeconds = parseInt(this.configService.config.samlPollTimeoutSeconds, 10) || 120;
    this.samlPollIntervalMs = parseInt(this.configService.config.samlPollIntervalMs, 10) || 1500;
  }

  getUserName(): string {
    return window.localStorage.getItem('username');
  }

  // Resolves to a list of UserProfiles for currently logged in user
  async getUserProfiles(): Promise<any> {
    return this.http
      .get(`${this.API_URL}/${this.API_VERSION}/Api/Me/UserProfiles`, {
        withCredentials: true
      })
      .toPromise();
  }

  login(username: string, password: string) {
    window.localStorage.setItem('username', username);
    return this.http
      .post(
        `${this.API_URL}/${this.API_VERSION}/Api/Session`,
        {
          UserName: username,
          Password: password
        },
        { withCredentials: true }
      )
      .toPromise();
  }

  async isActive(): Promise<boolean> {
    return this.http
      .get(`${this.API_URL}/${this.API_VERSION}/Api/StatusCheck/IsActive`, {
        withCredentials: true,
        headers: {
          'Cache-Control': 'no-cache'
        }
      })
      .toPromise()
      .then((isActive: boolean) => isActive);
  }

  hasLicense(): Promise<boolean> {
    return this.http
      .get(`${this.API_URL}/${this.API_VERSION}/Api/Licensing/checkLicense`, {
        withCredentials: true
      })
      .toPromise()
      .then(
        (hasLicense: boolean) => Promise.resolve(hasLicense),
        (error) => {
          this.loggerService.logger.logError(`Error getting hasLicense: ${JSON.stringify(error)}`);
          return Promise.reject(error);
        }
      );
  }

  isTokenPastHalfway() {
    return Date.now() >= Math.floor((this.latestTokenExpiration - this.latestTokenIssue) / 2) + this.latestTokenIssue;
  }

  stopTimersAndLogout() {
    this.loggerService.logger.logDebug('Renew operation cancelled by another process, stopped timers and logging out');

    this.logout('Renew operation cancelled by another process, stopped timers and logging out (initiated from agent)');

    // Fixes the IE bug, don't redirect if the URL is null,
    if (this._storageService.getSoftphoneUrl()) {
      document.location.href = this._storageService.getSoftphoneUrl();
    } else {
      this.loggerService.logger.logError('api.service.ts stopTimersAndLogout(): Softphone URL in storage service is null, bypassing page reload.');
    }
  }

  async backgroundTokenRenew() {
    try {
      if (await this.isActive()) {
        this.http
          .get(`${this.API_URL}/${this.API_VERSION}/Api/Session/renewToken`, {
            withCredentials: true
          })
          .toPromise()
          .then(
            (data: any) => {
              this.backgroundRetryAttemptNumber = 0;
              this.latestTokenIssue = Date.now();
              this.latestTokenExpiration = Date.parse(data.Expiration);
            },
            (error) => {
              this.loggerService.logger.logError(`Error fetching renewed token from API: ${JSON.stringify(error)}`);

              // User does not exist anymore, or has lost access
              if (error.status === 403) {
                this.cancelRenew = true;
                this.stopTimersAndLogout();
              } else {
                // Some other error, we should retry
                this.backgroundRetryAttemptNumber++;

                if (this.backgroundRetryAttemptNumber <= this.maxRetries) {
                  setTimeout(() => {
                    this.backgroundTokenRenew();
                  }, this.retryDelay);
                } else {
                  this.loggerService.logger.logError(`Error fetching renewed token from API, all retry attempts failed: ${JSON.stringify(error)}`);

                  this.stopTimersAndLogout();
                }
              }
            }
          );
      } else {
        this.loggerService.logger.logDebug('Connection is inactive, cancelling renew, logging out, and redirecting to login screen');

        this.stopTimersAndLogout();
      }
    } catch (error) {
      this.loggerService.logger.logError(`Error while attempting to renew token: ${JSON.stringify(error)}`);
    }
  }

  async logout(reason?: string) {
    // Clear profileID in session storage so it can be reselected
    localStorage.removeItem('profileID');
    let msg = 'Logging out initiated by agent';
    if (reason) {
      msg = msg + `, reason : ${reason}`;
    }
    this.loggerService.logger.logDebug(msg + ' - START');
    try {
      if (this.backgroundTokenRenewTimer !== null) {
        clearInterval(this.backgroundTokenRenewTimer);
        this.backgroundTokenRenewTimer = null;
      }

      this.cancelRenew = true;

      // Push all the logs to cloud
      await this.loggerService.logger.pushLogsAsync();

      await this.http
        .post(`${this.API_URL}/${this.API_VERSION}/Api/session/logout`, '', {
          withCredentials: true
        })
        .toPromise();

      this.ps.flushPermissions();
      this.loggerService.logger.logDebug(msg + ' - END');
    } catch (error) {
      this.loggerService.logger.logError(msg + ` - ERROR: ${JSON.stringify(error)}`);
    }

    localStorage.setItem('ForceLogoutRefresh', String(new Date()));
  }

  async triggerUpdateToLoginAuthInfoSubscriber(username: string): Promise<BaseAuthInfo[]> {
    return await this.http
      .get(`${this.agentUrl}/Api/authinfo/user/${encodeURIComponent(username)}`)
      .toPromise()
      .then(
        (authinfos: BaseAuthInfo[]) => {
          this.loginAuthInfos$.next(authinfos);
          return Promise.resolve(authinfos);
        },
        (error) => {
          this.loggerService.logger.logError(`Error getting loginAuthInfos: ${JSON.stringify(error)}`);
          return Promise.reject(error);
        }
      );
  }

  async getAuthInfoByAccountAuthId(id: Guid) {
    return await this.http
      .get(`${this.agentUrl}/Api/authinfo/accountauthinfo/${id}`)
      .toPromise()
      .then(
        (authinfo: BaseAuthInfo) => Promise.resolve(authinfo),
        (error) => {
          this.loggerService.logger.logError(`Error getting loginAuthInfos: ${JSON.stringify(error)}`);
          return Promise.reject(error);
        }
      );
  }

  getLoginAuthInfosAsObservable() {
    return this.loginAuthInfos$.asObservable();
  }

  startSAMLPolling(body: any): Observable<boolean> {
    return timer(0, this.samlPollIntervalMs).pipe(
      takeUntil(timer(this.samlPollTimeoutSeconds * 1000)),
      takeUntil(this.stopPolling$),
      switchMap(() => this.http.post(`${this.API_URL}/${this.API_VERSION}/Api/Session/GetToken`, body, { observe: 'response' })),
      switchMap((response) => {
        if (response && response.status !== 202) {
          this.stopSAMLPolling();
          return of(true);
        }

        return of(false);
      })
    );
  }

  async retrieveSAMLTokens(body: any) {
    try {
      return await this.http
        .post(`${this.API_URL}/${this.API_VERSION}/Api/Session/GetSAMLTokens`, body, {
          withCredentials: true
        })
        .toPromise()
        .then((data: any) => {
          if (!data.samlJwtToken || !data.appJwtToken) {
            this.loggerService.logger.logError(`Error getting SAMLTokens: ${JSON.stringify(data)}`);
            sendNotification('Error getting SAML Tokens', NOTIFICATION_TYPE.Error);
            return Promise.reject();
          }

          sessionStorage.setItem('samlJwtToken', data.samlJwtToken);
          sessionStorage.setItem('appJwtToken', data.appJwtToken);
          return Promise.resolve();
        });
    } catch (e) {
      this.loggerService.logger.logError(`Error getting SAMLTokens: ${JSON.stringify(e)}`);
      sendNotification('Error getting SAML Tokens', NOTIFICATION_TYPE.Error);
      return Promise.reject();
    }
  }

  stopSAMLPolling(): void {
    this.stopPolling$.next();
  }

  public async GetAuthInfoByAccountId(accountid) {
    return await this.http
      .get(`${this.agentUrl}/Api/authinfo/account/${encodeURIComponent(accountid)}`)
      .toPromise()
      .then(
        (authinfos: BaseAuthInfo[]) => {
          this.loginAuthInfos$.next(authinfos);
          return Promise.resolve(authinfos);
        },
        (error) => {
          this.loggerService.logger.logError(`Error getting loginAuthInfos: ${JSON.stringify(error)}`);
          return Promise.reject(error);
        }
      );
  }
}
