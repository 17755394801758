import { IInteraction, OPERATIONS } from '../api/AmcApi';

import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Queue } from '../model/Queue';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  public interactionQueue: Queue<IInteraction> = new Queue<IInteraction>();

  constructor(private dataService: DataService, private loggerService: LoggerService) {}

  public enqueueInteraction(data: any, pluginName: string, callback: (data: any, pluginName: string) => Promise<void>): void {
    const functionName = 'enqueueInteraction';
    try {
      this.loggerService.logger.logTrace(
        `${functionName} | Received event to enqueue from plugin: ${pluginName ? pluginName : 'No pluginName provided'} for interactionId: ${
          data?.data[0]?.interactionId
        }, scenarioId: ${data?.data[0]?.scenarioId}`
      );
      const interaction: IInteraction = data.data[0];
      const isSourceUpdate = data.operation === OPERATIONS.INTERACTION;

      if (isSourceUpdate) {
        if (interaction.scenarioId !== undefined) {
          interaction.scenarioId = pluginName.concat(interaction.scenarioId.toString());
        }

        interaction.interactionId = pluginName.concat(interaction.interactionId.toString());

        this.loggerService.logger.logTrace(`${functionName} -
          Changed interaction Ids to scenarioId ${interaction.scenarioId} and interactionId ${interaction.interactionId}.`);
      }

      this.interactionQueue.enqueue(interaction);

      if (this.interactionQueue.size() === 1) {
        this.loggerService.logger.logTrace(
          `${functionName} | Processing interaction queue. interactionId: ${interaction.interactionId}, scenarioId: ${
            interaction.scenarioId
          }, queue size: ${this.interactionQueue.size()}, pluginName: ${pluginName}`
        );
        this.processInteractionQueue(data, isSourceUpdate, pluginName, callback);
      } else {
        this.loggerService.logger.logTrace(
          `${functionName} | Queued interaction. interactionId: ${data?.data[0]?.interactionId}, scenarioId: ${
            data?.data[0]?.scenarioId
          }, queue size: ${this.interactionQueue.size()}`
        );
      }
    } catch (error) {
      this.loggerService.logger.logError(
        `${functionName} | Error enqueueing interaction ${data?.data[0]?.interactionId}, scenarioId: ${data?.data[0]?.scenarioId}, pluginName: ${
          pluginName ? pluginName : 'No pluginName provided'
        } Error: ${JSON.stringify(error)}`
      );
    }
  }

  async processInteractionQueue(
    data: any,
    isSourceUpdate: boolean,
    pluginName: string,
    callback: (data: any, pluginName: string) => Promise<void>
  ): Promise<void> {
    const functionName = 'processInteractionQueue';
    try {
      while (!this.interactionQueue.isEmpty()) {
        try {
          this.loggerService.logger.logLoop(
            `${functionName} | Handling queued interaction. interactionId: ${data?.data[0]?.interactionId}, scenarioId: ${
              data?.data[0]?.scenarioId
            }, queue size: ${this.interactionQueue.size()}, pluginName: ${pluginName}`
          );
          const interaction = this.interactionQueue.peek();
          const dataInteraction: IInteraction | void = this.dataService.addInteraction(interaction, isSourceUpdate, pluginName);

          if (dataInteraction) {
            dataInteraction.transcripts = interaction.transcripts;
            dataInteraction.analytics = interaction.analytics;
            data.data[0] = dataInteraction;

            await callback(data, pluginName);
          } else {
            this.loggerService.logger.logError(
              `${functionName} | Interaction missing from Data Service, interactionId: ${interaction.interactionId}, scenarioId: ${interaction.scenarioId}`
            );
          }
        } catch (error) {
          this.loggerService.logger.logError(
            `${functionName} | Error while processing interaction. interactionId ${data?.data[0]?.interactionId}, scenarioId: ${
              data?.data[0]?.scenarioId
            } Error: ${JSON.stringify(error)}`
          );
        } finally {
          this.loggerService.logger.logDebug(
            `${functionName} | Dequeueing interaction. interaction ${data?.data[0]?.interactionId}, scenarioId: ${
              data?.data[0]?.scenarioId
            } queue size: ${this.interactionQueue.size()}`
          );
          this.interactionQueue.dequeue();
        }
      }
    } catch (error) {
      this.loggerService.logger.logError(`${functionName} | Error processing interactions queue. Error: ${JSON.stringify(error)}`);
    }
  }
}
