import { Configuration } from './Configurations';

export default class Profile {
  Configurations: any[];
  apiUrl: string;
  apiVersion: string;
  profile: string;

  constructor(apiUrl: string, apiVersion: string) {
    this.apiUrl = apiUrl;
    this.apiVersion = apiVersion;
    this.Configurations = [];
  }

  // Sets the Configuration array to a list of Configurations objects,
  // then passes this array to the response promise
  GetProfileAsync(profileID?: string) {
    return new Promise(
      function (resolve: any, reject: any) {
        const xhr = new XMLHttpRequest();
        xhr.onloadend = function () {
          if (xhr.status === 404) {
            reject('Profile Not Found'); // alert('ERROR FOR PROFILES: ' + JSON.stringify(xhr));
            window.location.pathname = '/error/profile';
          }
        };
        xhr.onload = function () {
          if (xhr.responseText.length === 0) {
            resolve(this.Configurations);
            return;
          }

          const response = JSON.parse(xhr.responseText);
          for (const name of Object.keys(response)) {
            this.Configurations.push({
              name,
              ...this.ParseConfigurationTree(response[name].config)
            });
          }
          resolve(this.Configurations);
        }.bind(this); // Pass Profile Object to xhr callback
        xhr.open('GET', `${this.apiUrl}/${this.apiVersion}/Api/Me/Configuration/${profileID ? '?profileID=' + profileID : ''}`);
        xhr.withCredentials = true;
        xhr.send();
      }.bind(this)
    );
  }

  // Turns the AdminTool JSON format to a usable name-accessible object
  ParseConfigurationTree(config: Configuration) {
    const parsedTree: any = {};
    parsedTree.variables = {};
    // eslint-disable-next-line guard-for-in
    for (const i in config.variables) {
      if (config.variables[i].hasOwnProperty('!important') && config.variables[i].hasOwnProperty('!value')) {
        const variable: any = config.variables[i];
        if (variable['!type'] && variable['!type'] === 'SECURE') {
          const newValue = atob(variable['!value']);
          variable['!value'] = newValue;
        }
        config.variables[i] = variable['!value'];
      }
      if (config.variables[i].hasOwnProperty('!options')) {
        const selection: any = config.variables[i];
        parsedTree.variables[i] = selection['!selection'].value;
      } else {
        parsedTree.variables[i] = config.variables[i];
      }
    }
    // eslint-disable-next-line guard-for-in
    for (const i in config.nodes) {
      parsedTree[config.nodes[i].title] = this.ParseConfigurationTree(config.nodes[i]);
    }
    return parsedTree;
  }
}
