import { Injectable } from '@angular/core';
import { IInteraction, INTERACTION_STATES } from '../api/AmcApi';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _interactionMap: {
    [key: string]: {
      interaction: IInteraction;
      timestamp: number;
    };
  };

  constructor(private loggerService: LoggerService) {
    this._interactionMap = {};
  }

  /**
   * Adds an interaction to the map.
   * If the interaction already exists, its persistent fields will be overwritten with priority for the incoming interaction.
   * If the interaction does not have a Interaction Sequence ID, it will default to start with 1, and increment by 1 for each new interaction.
   * Increments the Interaction Sequence Update ID for each update.
   * If the update is coming from updateInteraction, it cannot overwrite existing CAD data.
   *
   * @param interaction - The interaction to add.
   * @param isSourceUpdate - A boolean which is true is the change is coming due to a setInteraction.
   */
  addInteraction(interaction: IInteraction, isSourceUpdate: boolean, pluginName: string): IInteraction | void {
    const functionName = 'addInteraction';

    try {
      this.loggerService.logger.logTrace(`${functionName} -
        Received interaction with scenarioId ${interaction.scenarioId} and interactionId ${interaction.interactionId}.`);

      if (interaction.interactionId in this._interactionMap) {
        // If the interaction already exists, its persistent fields will be overwritten with priority for incoming interaction
        this.loggerService.logger.logTrace(`${functionName} -
          Interaction with scenarioId ${interaction.scenarioId} exists in map, updating it.`);

        this.loggerService.logger.logTrace(
          `${functionName} - Received existing interaction with
          Interaction Sequence ID ${interaction.interactionSequenceId} and Interaction Sequence Update ID ${interaction.interactionSequenceUpdateId}.`
        );

        this._interactionMap[interaction.interactionId].timestamp = Date.now();

        if (isSourceUpdate) {
          // If the update is coming from setInteraction, it can overwrite existing CAD data
          this.loggerService.logger.logTrace(`${functionName} - Update is coming from setInteraction, isSourceUpdate=${isSourceUpdate}`);

          this.updateInteractionBySource(interaction);
        } else {
          // If the update is coming from updateInteraction, it cannot overwrite existing CAD data
          this.loggerService.logger.logTrace(`${functionName} - Update is coming from updateInteraction, isSourceUpdate=${isSourceUpdate}`);

          this.updateInteractionByProcessor(interaction);
        }
      } else {
        this.loggerService.logger.logTrace(
          `${functionName} - Interaction with scenarioId ${interaction.scenarioId} and interactionId ${interaction.interactionId} does not exist in map.`
        );

        if (!isSourceUpdate) {
          // An update for an interaction that does not exist is not allowed
          this.loggerService.logger
            .logError(`${functionName} - Cannot update an interaction that does not exist, isSourceUpdate=${isSourceUpdate} is not allowed
            for an interaction that does not exist.`);
          return;
        }

        // If the interaction doesn't exist, add it to the map
        // If the interaction does not have a Interaction Sequence ID, default start with 1, and increment by 1 for each new interaction
        if (!interaction.interactionSequenceId) {
          this.loggerService.logger.logTrace(`${functionName} - Incoming new interaction does not have a Interaction Sequence ID, defaulting to 0.`);
          interaction.interactionSequenceId = 0;
        }

        interaction.interactionSequenceUpdateId = 0;

        this.loggerService.logger.logTrace(
          `${functionName} - Received new interaction with Interaction Sequence ID ${interaction.interactionSequenceId}
          and Interaction Sequence Update ID ${interaction.interactionSequenceUpdateId}.`
        );

        const { transcripts, analytics, ...fieldsToInclude } = interaction;
        fieldsToInclude.pluginName = pluginName;

        this.loggerService.logger.logTrace(
          `${functionName} - Adding interaction with scenarioId ${interaction.scenarioId} and interactionId ${interaction.interactionId} to map.`
        );

        this._interactionMap[interaction.interactionId] = {
          interaction: fieldsToInclude,
          timestamp: Date.now()
        };
      }

      // Return a deep copy of the interaction
      // If complex objects are added (which they shouldn't as IInteraction does not support them),
      // this method will fail, consider using lodash cloneDeep instead then
      this.loggerService.logger.logTrace(`${functionName} - Returning a deep copy of the interaction with scenarioId ${interaction.scenarioId}.`);
      return JSON.parse(JSON.stringify(this._interactionMap[interaction.interactionId].interaction));
    } catch (error) {
      this.loggerService.logger.logError(
        `${functionName} - Error adding interaction with scenarioId ${interaction.scenarioId} and
        interactionId ${interaction.interactionId} to map - ${JSON.stringify(error)}`
      );
    }
  }

  cleanupInteractions(expirationTime: number): void {
    const functionName = 'cleanupInteractions';

    try {
      for (const interactionId in this._interactionMap) {
        if (
          this._interactionMap.hasOwnProperty(interactionId) &&
          this._interactionMap[interactionId].interaction.state === INTERACTION_STATES.Disconnected &&
          Date.now() - this._interactionMap[interactionId].timestamp > expirationTime
        ) {
          delete this._interactionMap[interactionId];
          this.loggerService.logger.logLoop(`${functionName} - Removed interaction with interactionId ${interactionId} from map.`);
        }
      }
    } catch (error) {
      this.loggerService.logger.logError(
        `${functionName} - Error cleaning up disconnected interactions older than ${expirationTime} milliseconds - ${JSON.stringify(error)}`
      );
    }
  }

  private updateInteractionBySource(interaction: IInteraction): void {
    const functionName = 'updateInteractionBySource';

    try {
      this.loggerService.logger.logTrace(
        `${functionName} - Received interaction with scenarioId ${interaction.scenarioId}
        and interactionId ${interaction.interactionId}.`
      );

      // If the update is coming from setInteraction, it can overwrite conflicting CAD data
      // If the interaction does not have a Interaction Sequence ID provided by source, increment existing Interaction Sequence ID by 1
      if (!interaction.interactionSequenceId) {
        this.loggerService.logger.logTrace(
          `${functionName} - Incoming new interaction does not have a Interaction Sequence ID, incrementing existing Interaction Sequence ID by 1.`
        );

        interaction.interactionSequenceId = this._interactionMap[interaction.interactionId].interaction.interactionSequenceId + 1;
      }

      // Since new Interaction Sequence ID, reset Interaction Sequence Update ID to 0
      interaction.interactionSequenceUpdateId = 0;

      this.loggerService.logger.logTrace(
        `${functionName} - Updating interaction with scenarioId ${interaction.scenarioId}
        which has Interaction Sequence ID ${interaction.interactionSequenceId} and Interaction Sequence Update ID ${interaction.interactionSequenceUpdateId}.`
      );

      const { details, transcripts, analytics, pluginName, ...fieldsToOverwrite } = interaction;

      this.loggerService.logger.logTrace(
        `${functionName} - Overwriting all fields except 'details', 'transcripts' and 'analytics' for
        interaction with scenarioId ${interaction.scenarioId}.`
      );

      this._interactionMap[interaction.interactionId].interaction = {
        ...this._interactionMap[interaction.interactionId].interaction,
        ...fieldsToOverwrite
      };

      this.loggerService.logger.logTrace(`${functionName} - Updating 'details' for interaction with scenarioId ${interaction.scenarioId}.`);

      this._interactionMap[interaction.interactionId].interaction.details.id = details.id;
      this._interactionMap[interaction.interactionId].interaction.details.type = details.type;
      this._interactionMap[interaction.interactionId].interaction.details.displayName = details.displayName;

      for (const field in details.fields) {
        if (details.fields.hasOwnProperty(field)) {
          this._interactionMap[interaction.interactionId].interaction.details.fields[field] = details.fields[field];
        }
      }
    } catch (error) {
      this.loggerService.logger.logError(
        `${functionName} - Error updating interaction with scenarioId ${interaction.scenarioId} and
        interactionId ${interaction.interactionId} to map - ${JSON.stringify(error)}`
      );
    }
  }

  private updateInteractionByProcessor(interaction: IInteraction): void {
    const functionName = 'updateInteractionByProcessor';

    try {
      this.loggerService.logger.logTrace(
        `${functionName} - Updating interaction with scenarioId ${interaction.scenarioId}
        which has Interaction Sequence ID ${interaction.interactionSequenceId} and Interaction Sequence Update ID ${interaction.interactionSequenceUpdateId}.`
      );

      // If the update is coming from updateInteraction, it cannot overwrite existing CAD data
      // Since an update is coming from updateInteraction, increment existing Interaction Sequence Update ID by 1
      this._interactionMap[interaction.interactionId].interaction.interactionSequenceUpdateId += 1;

      this.loggerService.logger.logTrace(
        `${functionName} - Updated Interaction Sequence Update ID for interaction with scenarioId ${interaction.scenarioId}
        to have Interaction Sequence Update ID ${this._interactionMap[interaction.interactionId].interaction.interactionSequenceUpdateId}.`
      );

      // Updates cannot overwrite existing CAD data, but should it be allowed to update CAD data with a specific
      // prefix perhaps? for example if prefix is '_update', or if CAD doesn't exist, then we allow it?
      // This way, we won't end up with a bunch of CAD data that is not being used, and we can still update
      this.loggerService.logger.logTrace(`${functionName} - Updating 'details' for interaction with scenarioId ${interaction.scenarioId}.`);

      for (const field in interaction.details.fields) {
        if (interaction.details.fields.hasOwnProperty(field) && !this._interactionMap[interaction.interactionId].interaction.details.fields[field]) {
          this._interactionMap[interaction.interactionId].interaction.details.fields[field] = interaction.details.fields[field];
        } else {
          this.loggerService.logger.logLoop(
            `${functionName} - Field ${field} already exists in 'details' for
            interaction with scenarioId ${interaction.scenarioId}. Can not overwrite.`
          );
        }
      }
    } catch (error) {
      this.loggerService.logger.logError(
        `${functionName} - Error updating interaction with scenarioId ${interaction.scenarioId} and
        interactionId ${interaction.interactionId} to map - ${JSON.stringify(error)}`
      );
    }
  }
}
