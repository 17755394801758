import { ERROR_CODE } from './../api/Logger/ErrorCode';
import { LoginTimeoutService } from './login-timeout.service';
import { Injectable } from '@angular/core';
import { bind } from 'bind-decorator';
import * as $ from 'jquery';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import {
  CHANNEL_TYPES,
  getSequenceID,
  IAppConfiguration,
  IContextualContact,
  IContextualContactChannel,
  INotificationMessage,
  INTERCEPTOR_TIMEOUT_ACTION,
  IUserDetails,
  Logger,
  NOTIFICATION_TYPE,
  OPERATIONS
} from '../api/AmcApi';
import * as Helper from '../api/HelperFunctions';
import { IRequest, IResponse, isResponse, LOG_SOURCE } from '../api/HelperFunctions';
import { defaultGlobalConfiguration, IGlobalConfiguration } from '../model/GlobalConfiguration';
import { IContextualOperation } from '../model/IContextualOperation';

import { DaVinciApp } from '../model/Plugin';
import Profile from '../Scripts/Profile';
import { ConfigurationService } from './configuration.service';
import { ContextualContactsService } from './contextual-contacts.service';
import { ILoggerConfiguration, defaultLoggerConfiguration } from '../api/models/LoggerConfiguration';
import { LoggerService } from './logger.service';
import { DataService } from './data.service';
import { QueueService } from './queue.service';

@Injectable({
  providedIn: 'root'
})
export class OFHelper {
  static supportedChannelsUpdated$ = new Subject<void>();
  static selectedChannelApp$ = new ReplaySubject<DaVinciApp>(1);
  static populateDialpad$ = new Subject<string>();
  static replaceDialpad$ = new Subject<string>();
  private selectedChannelApp: DaVinciApp;
  static finishedInitializingPlugins = false;
  static notificationMessages$: BehaviorSubject<INotificationMessage[]> = new BehaviorSubject<INotificationMessage[]>([]);

  static plugins: {
    [name: string]: DaVinciApp;
  } = {};

  // Convert channel filters to enum. Remove any invalid ones
  private channelTypeFilter: CHANNEL_TYPES[] = [];
  lastDocumentHeight: number;
  appsLoaded = new BehaviorSubject<boolean>(false);
  shouldSendSetHeightEvents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // Variables to handle caching events until all plugins load

  validOperationsBeforeLoad = [
    // TODO: document this
    OPERATIONS.INITIALIZE_COMPLETE,
    OPERATIONS.LOAD_SCRIPT,
    OPERATIONS.GET_CONFIG,
    OPERATIONS.GET_APP_NAME,
    OPERATIONS.GET_DAVINCI_AGENT_CONFIG
  ];

  queuedEvents: MessageEvent[] = [];
  pluginConfigs$ = new ReplaySubject<any>(1);
  globalConfiguration$ = new ReplaySubject<IGlobalConfiguration>(1);
  globalConfiguration: IGlobalConfiguration;
  davinciAgentConfig: IAppConfiguration;
  interceptorConfig: any;
  loggingOut$ = new ReplaySubject<boolean>(1);
  onAppRegister$ = new Subject<string>();
  onSpeedDial$ = new Subject<string>();
  onAddContextualContacts$ = new Subject<[string, IContextualContact[]]>();
  onClearContextualContacts$ = new Subject<string>();
  contextualOperation$ = new Subject<IContextualOperation>();
  registeredOperations$ = new Subject<any>();
  layerToGrayOutBelow$ = new Subject<number>();
  layerToGrayOutBelow: number;

  private interactionCleanupFrequency: number;
  private interactionExpirationTime: number;

  // TODO: figure out how to test this file
  // Integration test with APIs
  // How to test with dom?
  operationHandlers: {
    [operation: number]: {
      operationHandler?: (originPlugin: string, operation: OPERATIONS, data?: any) => void;
      operationResponseHandler?: (respondingPlugin: string, operation: OPERATIONS, data?: any) => void;
      operationRegisterHandler?: (pluginName: string, operation: OPERATIONS, message?: any) => void;
    };
  } = {};

  registeredOperations: {
    [operation: number]: [string]; // operation => pluginName[]
  } = {};

  registeredInterceptors: {
    [operation: number]: [
      {
        pluginName: string;
        timeoutInMilliseconds: number;
        timeoutAction: INTERCEPTOR_TIMEOUT_ACTION;
      }
    ];
  } = {};

  requestedOperations: {
    [requestId: string]: {
      originPluginName: string;
      timeout: number;
      callback?: (respondingPlugin: string, operation: OPERATIONS, data?: any) => void;
    };
  } = {};

  cachedRequests: {
    [operation: number]: IRequest;
  } = {};

  private apiUrl: string;
  private apiVersion: string;

  // Note that we have to declare the customer operation handlers individually
  // because if we declare them all at once 'Operations' is not accessible
  constructor(
    configurationService: ConfigurationService,
    private loginTimeoutService: LoginTimeoutService,
    private loggerService: LoggerService,
    private dataService: DataService,
    private queueService: QueueService
  ) {
    OFHelper.selectedChannelApp$.subscribe((val) => (this.selectedChannelApp = val));
    this.initialize(configurationService);
    this.shouldSendSetHeightEvents$.subscribe((shouldSend) => {
      if (shouldSend) {
        this.sendSetSoftphoneHeight();
      }
    });
  }

  // eslint-disable-next-line max-statements
  async initialize(configurationService: ConfigurationService) {
    const config = await configurationService.configData$.toPromise();
    this.apiUrl = config.apiUrl;
    this.apiVersion = config.apiVersion;
    this.globalConfiguration$.subscribe((globalConfiguration: IGlobalConfiguration) => {
      this.globalConfiguration = globalConfiguration;

      // Convert channel filters to enum. Remove any invalid ones
      this.channelTypeFilter = this.globalConfiguration.LivePresenceChannelTypeFilter.map((type) => CHANNEL_TYPES[type]).filter((type) => type !== undefined);
    });

    this.operationHandlers[OPERATIONS.INTERCEPT] = {
      operationHandler: (appName: string, operation: OPERATIONS, message?: IRequest) => {},
      operationResponseHandler: () => {},
      // eslint-disable-next-line max-statements
      operationRegisterHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        this.loggerService.logger.logInformation(
          `OFHelper - operationRegisterHandler - ${JSON.stringify(pluginName)} registered for ${JSON.stringify(OPERATIONS[operation])}`
        );

        // Adds the plugin to the list of interceptors for the given operation
        const [operationsToIntercept, timeoutInMilliseconds, timeoutAction] = message.data;

        // Reject if no operation is included
        if (!operationsToIntercept && operationsToIntercept.length == null && operationsToIntercept.length <= 0) {
          const response: IResponse = {
            request: message,
            isResponse: true,
            reject: 'No Operation defined',
            resolve: undefined
          };
          OFHelper.sendMessageToPlugin(pluginName, response);
          return;
        }

        try {
          // add app to list of registered interceptors for each operation passed
          for (const operationToIntercept of operationsToIntercept) {
            let alreadyRegistered = false;
            if (this.registeredInterceptors[operationToIntercept]) {
              for (const interceptor of this.registeredInterceptors[operationToIntercept]) {
                if (interceptor.pluginName === pluginName) {
                  alreadyRegistered = true;
                }
              }

              if (!alreadyRegistered) {
                // Check if this intercept operation should be ordered for which app gets the event first
                const enumKey = OPERATIONS[operationToIntercept];
                if (this.interceptorConfig && this.interceptorConfig.hasOwnProperty(enumKey)) {
                  // Events should be sequenced: There is in order provided in the config
                  const interceptorConfigForCurrentOperation: string[] = this.interceptorConfig[enumKey];

                  let didRegistrationHappen = false;
                  for (let i = 0; i < this.registeredInterceptors[operationToIntercept].length; i++) {
                    // Check if the current registration index is a "lower" priority (higher index) than the current app trying to register
                    // If so, put the currentlyRegisteringPlugin into its place and shift every other app forward
                    if (
                      interceptorConfigForCurrentOperation.includes(this.registeredInterceptors[operationToIntercept][i].pluginName) &&
                      interceptorConfigForCurrentOperation.includes(pluginName)
                    ) {
                      if (
                        interceptorConfigForCurrentOperation.indexOf(this.registeredInterceptors[operationToIntercept][i].pluginName) >
                        interceptorConfigForCurrentOperation.indexOf(pluginName)
                      ) {
                        // The newly subscribing app is higher-priority than the alreadyRegisteredApp.
                        // Splice the new registration in place and shift everything forward
                        this.registeredInterceptors[operationToIntercept].splice(i, 0, {
                          pluginName,
                          timeoutInMilliseconds,
                          timeoutAction
                        });
                        didRegistrationHappen = true;
                        break;
                      }
                    }
                  }
                  if (!didRegistrationHappen) {
                    // A location to insert the newly registering app was not found.
                    // This may happen when the only apps that have registered
                    // so far are not included in the configuration.
                    // Push the newly subscribing app into the list.
                    this.registeredInterceptors[operationToIntercept].push({
                      pluginName,
                      timeoutInMilliseconds,
                      timeoutAction
                    });
                  }
                } else {
                  // No configuration provided for sequenced interceptor events,
                  // or none detected for this specific operation.
                  // Push the event (default behavior)
                  this.registeredInterceptors[operationToIntercept].push({
                    pluginName,
                    timeoutInMilliseconds,
                    timeoutAction
                  });
                }
              }
            } else {
              // First entry into the registeredInterceptors, no need to check for sorting
              this.registeredInterceptors[operationToIntercept] = [
                {
                  pluginName,
                  timeoutInMilliseconds,
                  timeoutAction
                }
              ];
            }
          }
        } catch (e) {
          this.loggerService.logger.logError('OFHelper: Error while registering interceptors. Error: ' + JSON.stringify(e));
        }

        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: undefined
        };
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };
    this.operationHandlers[OPERATIONS.CLICK_TO_DIAL] = {
      operationHandler: (appName: string, operation: OPERATIONS, message?: IRequest) => {
        const [phoneNumber, records, channelType] = message.data;
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const sendToDialPad =
          (channelType == null && this.selectedChannelApp != null && this.selectedChannelApp.supportedChannels.length > 1) ||
          this.registeredOperations[OPERATIONS.CLICK_TO_DIAL].length > 1;

        this.loggerService.logger.logDebug(
          `OFHelper: Sending Click-To-Dial to ${sendToDialPad ? 'dialpad' : 'registered listeners'} | Channel Type=${JSON.stringify(
            channelType
          )} | Selected CTI=${JSON.stringify(this.selectedChannelApp.name)} | Available Channels=${JSON.stringify(
            this.selectedChannelApp.supportedChannels.map((channel) => channel.idName)
          )} | # of CTD Listeners=${this.registeredOperations[OPERATIONS.CLICK_TO_DIAL].length}`
        );
        if (sendToDialPad) {
          OFHelper.replaceDialpad$.next(phoneNumber);

          const response: IResponse = {
            request: message,
            isResponse: true,
            reject: undefined,
            resolve: true
          };
          OFHelper.sendMessageToPlugin(appName, response);
        } else {
          this.defaultOperationHandler(appName, operation, message);
        }
      },
      operationRegisterHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        this.defaultRegisterOperationHandler(pluginName, operation, message);
      }
    };
    this.operationHandlers[OPERATIONS.CLICK_TO_ACT] = {
      operationHandler: (appName: string, operation: OPERATIONS, message?: IRequest) => {
        const [phoneNumber, records, channelType] = message.data;
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        if (channelType == null && this.selectedChannelApp != null && this.selectedChannelApp.supportedChannels.length > 1) {
          OFHelper.populateDialpad$.next(phoneNumber);

          const response: IResponse = {
            request: message,
            isResponse: true,
            reject: undefined,
            resolve: true
          };
          OFHelper.sendMessageToPlugin(appName, response);
        } else {
          this.defaultOperationHandler(appName, operation, message);
        }
      },
      operationRegisterHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        this.defaultRegisterOperationHandler(pluginName, operation, message);
      }
    };
    this.operationHandlers[OPERATIONS.SET_PRESENCE] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: IRequest) => {
        // presence: string, reason?: string, initiatingApp?: string
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        if (message.data.length < 2) {
          message.data[1] = undefined;
        }
        message.data[3] = pluginName;
        this.defaultOperationHandler(pluginName, operation, message);
      },
      operationRegisterHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        this.defaultRegisterOperationHandler(pluginName, operation, message);
      }
    };
    this.operationHandlers[OPERATIONS.CONTEXTUAL_EVENT] = {
      operationHandler: () => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
      },
      operationRegisterHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        this.onAppRegister$.next(pluginName);
        this.defaultRegisterOperationHandler(pluginName, operation, message);
      }
    };

    this.operationHandlers[OPERATIONS.INTERACTION] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: IRequest) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }

        OFHelper.plugins[pluginName].addAlert();
        this.defaultOperationHandler(pluginName, operation, message);
      }
    };

    this.operationHandlers[OPERATIONS.UPDATE_INTERACTION] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: IRequest) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }

        message.operation = OPERATIONS.INTERACTION;
        this.defaultOperationHandler(pluginName, OPERATIONS.INTERACTION, message);
      }
    };

    this.operationHandlers[OPERATIONS.SUPPORTED_CHANNEL] = {
      operationHandler: (appName: string, operation: OPERATIONS, message?: IRequest) => {
        let [channels] = message.data;
        if (channels != null && channels.length > 0) {
          OFHelper.plugins[appName].supportedChannels = channels;
          OFHelper.supportedChannelsUpdated$.next();
          channels = channels.map((channel) => ({
            ...channel,
            createdByApp: appName
          }));
          $.ajax({
            type: 'PUT',
            contentType: 'application/json; charset=utf-8',
            url: `${this.apiUrl}/${this.apiVersion}/Api/Presence/Channels`,
            xhrFields: {
              withCredentials: true
            },
            data: JSON.stringify(channels),
            success: () => {
              const response: IResponse = {
                request: message,
                isResponse: true,
                reject: undefined,
                resolve: true
              };
              OFHelper.sendMessageToPlugin(appName, response);
            },
            error: (d) => {
              this.loggerService.logger.logError(
                'Error in Operations.SUPPORTED_CHANNEL operation! Failed to send presence to server! Error=' + JSON.stringify(d)
              );
              const response: IResponse = {
                request: message,
                isResponse: true,
                reject: `Error: ${JSON.stringify(d)}`,
                resolve: undefined
              };
              OFHelper.sendMessageToPlugin(appName, response);
            }
          });
        }

        // Forward request to any registered plugins
        if (operation in this.registeredOperations && this.registeredOperations[operation].length > 0) {
          message.data = [appName, ...message.data];
          // eslint-disable-next-line guard-for-in
          for (const i in this.registeredOperations[operation]) {
            const targetPlugin = this.registeredOperations[operation][i];
            OFHelper.sendMessageToPlugin(targetPlugin, message);
          }
        }
      },
      operationResponseHandler(respondingPlugin: string, operation: OPERATIONS, data?: any) {
        // intentionally left blank. The real response will come from the framework, not from registered plugins returning a response.
      }
    };
    this.operationHandlers[OPERATIONS.MY_CALLS_TODAY] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: IRequest) => {
        if (message.data != null && message.data.length > 0) {
          const [url, openInNewWindow] = message.data;

          if (openInNewWindow === true || openInNewWindow == null) {
            // open in new browser tab
            window.open(url);

            const response: IResponse = {
              request: message,
              isResponse: true,
              reject: undefined,
              resolve: OFHelper.plugins[pluginName].config
            };
            OFHelper.sendMessageToPlugin(pluginName, response);
          } else {
            // open in crm
            this.defaultOperationHandler(pluginName, operation, message);
          }
        }
      }
    };
    this.operationHandlers[OPERATIONS.DIALPAD_NUMBER_CLICKED] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: IRequest) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: true
        };
        const existingTest = ($('#contextuaTextboxDiv').children('.searchTextField').val() || '').toString();
        $('#contextuaTextboxDiv')
          .children('.searchTextField')
          .val(existingTest + message.data[0].toString());
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };
    this.operationHandlers[OPERATIONS.SPEED_DIAL_NUMBER_CLICKED] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: IRequest) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: true
        };
        this.onSpeedDial$.next(...message.data);
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };
    // TODO: remove this after everyone upgrade to GET_USER_DETAILS
    this.operationHandlers[OPERATIONS.USER_INFO] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: IRequest) => {
        try {
          // FIX ME
          $.ajax({
            type: 'GET',
            dataType: 'application/text',
            url: `${this.apiUrl}/${this.apiVersion}/api/Me/Username`,
            xhrFields: {
              withCredentials: true
            },
            success: (result) => {
              const response: IResponse = {
                request: message,
                isResponse: true,
                reject: undefined,
                resolve: result
              };
              OFHelper.sendMessageToPlugin(pluginName, response);
            },
            error: (d) => {
              this.loggerService.logger.logError('Error handling Operations.USER_INFO operation! Error=' + d);
              const response: IResponse = {
                request: message,
                isResponse: true,
                reject: undefined,
                resolve: d.responseText
              };
              OFHelper.sendMessageToPlugin(pluginName, response);
            }
          });
        } catch (e) {
          this.loggerService.logger.logError('Error handling Operations.USER_INFO operation! Error=' + e);
          const response: IResponse = {
            request: message,
            isResponse: true,
            reject: e,
            resolve: undefined
          };
          OFHelper.sendMessageToPlugin(pluginName, response);
        }
      }
    };
    this.operationHandlers[OPERATIONS.LOGIN] = {
      operationHandler: this.createCacheRequestOperationHandler(this.broadcastMessageOperationHandler),
      operationRegisterHandler: this.createSendCachedOpRegisterHandler(this.defaultRegisterOperationHandler)
    };

    const onPresenceChangeOpHandler = this.createCacheRequestOperationHandler(this.broadcastMessageOperationHandler);
    this.operationHandlers[OPERATIONS.ON_PRESENCE_CHANGED] = {
      operationHandler: (appName: string, operation: OPERATIONS, message?: IRequest) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const [presence, reason, presenceColor, originatingApp] = message.data;
        if (presence != null) {
          const presenceData = {
            presence: presence,
            presenceColor: presenceColor, // color showing what presence the user is,
            reason: reason
          };

          message.data = [presence, reason, originatingApp]; // remove color as it is not forwarded to apps
          if (originatingApp !== 'GlobalPresence') {
            $.ajax({
              type: 'PUT',
              dataType: 'json',
              contentType: 'application/json; charset=utf-8',
              url: `${this.apiUrl}/${this.apiVersion}/Api/Presence`,
              xhrFields: {
                withCredentials: true
              },
              data: JSON.stringify(presenceData),
              success: (result) => {},
              error: (d) => {
                this.loggerService.logger.logError(
                  'Error in Operations.SET_PRESENCE operation! Failed to send presence to server! Error= ' + JSON.stringify(d)
                );
              }
            });
          }
        }

        onPresenceChangeOpHandler(appName, operation, message);
      },
      operationRegisterHandler: this.createSendCachedOpRegisterHandler(this.defaultRegisterOperationHandler)
    };
    this.operationHandlers[OPERATIONS.SCREENPOP_CONTROL_CHANGED] = {
      operationHandler: this.broadcastMessageOperationHandler
    };
    this.operationHandlers[OPERATIONS.INITIALIZE_COMPLETE] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, data?: any) => {
        OFHelper.plugins[pluginName].initializationCompleted = true;
        const response: IResponse = {
          request: data,
          isResponse: true,
          reject: undefined,
          resolve: OFHelper.plugins[pluginName].config
        };
        OFHelper.sendMessageToPlugin(pluginName, response);

        this.checkIfLastPluginToInitialize();

        this.sendAllCachedRequestForPlugin(pluginName);

        this.sendLoginToPlugin(pluginName);

        this.loggerService.logger.logDebug('completed: Operation handler for Initialize complete.');
      }
    };
    this.operationHandlers[OPERATIONS.GET_DAVINCI_AGENT_CONFIG] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, data?: any) => {
        const response: IResponse = {
          request: data,
          isResponse: true,
          reject: undefined,
          resolve: this.davinciAgentConfig
        };
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };
    this.operationHandlers[OPERATIONS.GET_CONFIG] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, data?: any) => {
        const response: IResponse = {
          request: data,
          isResponse: true,
          reject: undefined,
          resolve: OFHelper.plugins[pluginName].config
        };
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };
    this.operationHandlers[OPERATIONS.GET_APP_NAME] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, data?: any) => {
        const response: IResponse = {
          request: data,
          isResponse: true,
          reject: undefined,
          resolve: pluginName
        };
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };
    this.operationHandlers[OPERATIONS.SET_TOOLBAR_ENABLED] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        if (message.data.length > 0 && typeof message.data[0] === 'boolean') {
          const isEnabled = !$('#PluginContainer').hasClass('disabled');
          if (isEnabled === true && isEnabled !== message.data[0]) {
            let urlParameter: string;
            if (document.location.href.indexOf('?') > -1) {
              urlParameter = '&disabled=true';
            } else {
              urlParameter = '?disabled=true';
            }
            document.location.href = document.location.href + urlParameter;
          } else if (isEnabled === false && isEnabled !== message.data[0]) {
            if (document.location.href.indexOf('?disabled') > -1) {
              if (document.location.href.indexOf('disabled=true&') > -1) {
                document.location.href = document.location.href.replace('disabled=true&', '');
              } else {
                document.location.href = document.location.href.replace('?disabled=true', '');
              }
            } else {
              document.location.href = document.location.href.replace('&disabled=true', '');
            }
          }
        } else {
          this.loggerService.logger.logError('Invalid data passed to setToolbarEnabled! pluginName=' + pluginName + '; data=' + JSON.stringify(message.data));
        }

        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: undefined
        };
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };
    this.operationHandlers[OPERATIONS.ADD_CONTEXTUAL_ACCESS_LIST] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: undefined
        };
        try {
          this.onAddContextualContacts$.next([pluginName, message.data[0]]);
          OFHelper.sendMessageToPlugin(pluginName, response);
        } catch (e) {
          this.loggerService.logger.logError('Error handling ADD_CONTEXTUAL_ACCESS_LIST operation! Error=' + e);
          response.reject = e;
          OFHelper.sendMessageToPlugin(pluginName, response);
        }
      }
    };
    this.operationHandlers[OPERATIONS.CLEAR_CONTEXTUAL_ACCESS_LIST] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: undefined
        };
        try {
          this.onClearContextualContacts$.next(pluginName);
          OFHelper.sendMessageToPlugin(pluginName, response);
        } catch (e) {
          this.loggerService.logger.logError('Error handling CLEAR_CONTEXTUAL_ACCESS_LIST operation! Error=' + e);
          response.reject = e;
          OFHelper.sendMessageToPlugin(pluginName, response);
        }
      }
    };

    this.operationHandlers[OPERATIONS.SET_SOFTPHONE_HEIGHT] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        // eslint-disable-next-line prefer-const
        let [height, grayOutLayersBelow] = message.data;
        if (height <= 0 || !grayOutLayersBelow) {
          grayOutLayersBelow = false;
        }
        this.loggerService.logger.logTrace(
          `called setSoftphoneHandler with parameters: pluginname ${pluginName} , operations: ${operation} , and message ${JSON.stringify(message)}`,
          ERROR_CODE.Other
        );
        if (height <= 0) {
          OFHelper.plugins[pluginName].iframe.parentElement.classList.add('davinci-app-hidden');
        } else {
          OFHelper.plugins[pluginName].iframe.parentElement.classList.remove('davinci-app-hidden');
        }
        $(OFHelper.plugins[pluginName].iframe).css('height', height + 'px');

        setTimeout(
          (() => {
            const oldGrayOutLayersBelow = $(OFHelper.plugins[pluginName].iframe).attr('GrayOutLayersBelow') || false;
            if (oldGrayOutLayersBelow !== grayOutLayersBelow) {
              $(OFHelper.plugins[pluginName].iframe).attr('GrayOutLayersBelow', grayOutLayersBelow);
              this.updateLayerToGrayOut();
            }

            this.sendSetSoftphoneHeight();

            const response: IResponse = {
              request: message,
              isResponse: true,
              reject: undefined,
              resolve: undefined
            };
            OFHelper.sendMessageToPlugin(pluginName, response);
          }).bind(this),
          300
        );
      }
    };

    this.operationHandlers[OPERATIONS.SET_SOFTPHONE_WIDTH] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const width = message.data[0];
        if (width <= 0) {
          OFHelper.plugins[pluginName].iframe.parentElement.classList.add('davinci-app-hidden');
        } else {
          OFHelper.plugins[pluginName].iframe.parentElement.classList.remove('davinci-app-hidden');
        }
        $(OFHelper.plugins[pluginName].iframe).css('width', width + 'px');

        if (operation in this.registeredOperations && this.registeredOperations[operation].length > 0) {
          const documentWidth = this.getSoftPhoneWidth(); // window.document.body.scrollWidth;
          const newMessage: IRequest = {
            operation: operation,
            data: [documentWidth]
          };
          // eslint-disable-next-line guard-for-in
          for (const i in this.registeredOperations[operation]) {
            const targetPlugin = this.registeredOperations[operation][i];
            OFHelper.sendMessageToPlugin(targetPlugin, newMessage);
          }
        }

        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: undefined
        };
        OFHelper.sendMessageToPlugin(pluginName, response);
      }
    };

    this.operationHandlers[OPERATIONS.CONTEXTUAL_OPERATION] = {
      operationHandler: (appName: string, operation: OPERATIONS, message: any) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const [requestedOperation, channelType] = message.data;
        this.contextualOperation$.next({
          appName,
          requestedOperation,
          channelType,
          requestedOperationMessage: message
        });
      }
    };

    this.operationHandlers[OPERATIONS.GET_LIVE_AGENTS] = {
      operationHandler: async (pluginName: string, operation: OPERATIONS, message?: any) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }

        $.ajax({
          type: 'GET',
          dataType: 'json',
          contentType: 'application/json; charset=utf-8',
          url: `${this.apiUrl}/${this.apiVersion}/Api/Presence`,
          xhrFields: {
            withCredentials: true
          },
          async: false,
          success: (contacts) => {
            this.parseContacts(contacts) // parse the contacts to correct type
              .filter(
                (
                  contact // Live presence filters
                ) =>
                  this.filterByGroupName(contact) &&
                  this.filterByPresence(contact) &&
                  this.filterByApp(contact) &&
                  this.filterByChannelType(contact) &&
                  this.filterByChannelPresence(contact)
              );

            const response: IResponse = {
              request: message,
              isResponse: true,
              reject: undefined,
              resolve: contacts
            };
            OFHelper.sendMessageToPlugin(pluginName, response);
          },
          error: (d) => {
            this.loggerService.logger.logError('Error in Operations.GET_LIVE_AGENTS operation! Failed to send presence to server! Error=' + JSON.stringify(d));
            const response: IResponse = {
              request: message,
              isResponse: true,
              reject: `Error: ${JSON.stringify(d)}`,
              resolve: undefined
            };
            OFHelper.sendMessageToPlugin(pluginName, response);
          }
        });
      }
    };

    this.operationHandlers[OPERATIONS.LOGOUT] = {
      operationHandler: async (pluginName: string, operation: OPERATIONS, message?: any) => {
        let msg = 'Logging out';
        if (pluginName) {
          msg = msg + ` initiated by : ${pluginName},`;
        }
        if (message) {
          msg = msg + ` reason : ${JSON.stringify(message)}`;
        }
        this.loggerService.logger.logDebug(msg);

        this.loggingOut$.next(true);

        // eslint-disable-next-line guard-for-in
        for (const i in this.registeredOperations[operation]) {
          const targetPlugin = this.registeredOperations[operation][i];
          OFHelper.sendMessageToPlugin(targetPlugin, message);
        }

        // send request to presence api to remove from list
        const presenceData = {
          presence: 'LoggedOut',
          presenceColor: 'black' // color showing what presence the user is
        };

        $.ajax({
          type: 'PUT',
          dataType: 'json',
          contentType: 'application/json; charset=utf-8',
          url: `${this.apiUrl}/${this.apiVersion}/Api/Presence`,
          xhrFields: {
            withCredentials: true
          },
          data: JSON.stringify(presenceData),
          async: false,
          success: (result) => {},
          error: (d) => {
            this.loggerService.logger.logError('Error in Operations.SET_PRESENCE operation! Failed to send presence to server! Error=' + JSON.stringify(d));
          }
        });

        // Push all the logs to cloud
        try {
          await this.loggerService.logger.pushLogsAsync();
        } catch (error) {
          // ToDo: Identify why flushing the logs before logout is failing when running locally
          console.log('Error while pushing logs to cloud before logout.', error);
        }

        this.apiUrl = this.apiUrl;
        window.setTimeout(async () => {
          $.ajax({
            headers: {
              'Content-Type': 'application/json'
            },
            url: `${this.apiUrl}/${this.apiVersion}/api/session/logout`,
            xhrFields: {
              withCredentials: true
            },
            type: 'POST',
            success: () => {
              const softphoneUrl = localStorage.getItem('softphoneUrl');
              if (softphoneUrl) {
                document.location.href = softphoneUrl;
              } else {
                const urlParams = document.location.href.split('?');
                document.location.href = urlParams.length >= 2 ? `${document.location.origin}?${urlParams[1]}` : document.location.origin;
              }
            },
            error: (error) => {
              this.loggerService.logger.logDebug(msg + ` - ERROR: ${JSON.stringify(error)}`);
            }
          });
        }, 5000);
      }
    };
    this.operationHandlers[OPERATIONS.LOAD_SCRIPT] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const response: IResponse = {
          request: message,
          isResponse: true,
          reject: undefined,
          resolve: undefined
        };
        const loadScripts = async () => {
          try {
            const promises: Promise<void>[] = [];
            for (let i = 0; i < message.data[0].length; i++) {
              promises[i] = new Promise<void>((resolve, reject) => {
                const script = document.createElement('script');
                script.src = message.data[0][i];
                script.type = 'application/javascript';
                const timeout = window.setTimeout(() => {
                  reject('Failed to load script! url=' + message.data[0][i]);
                }, 50000);
                script.onload = () => {
                  window.clearTimeout(timeout);
                  resolve();
                };
                document.head.appendChild(script);
              });
            }

            // eslint-disable-next-line guard-for-in
            for (const i in promises) {
              await promises[i];
            }
          } catch (e) {
            this.loggerService.logger.logError('Error handling LOAD_SCRIPT operation! Error=' + e);
            response.reject = 'Failed to load one or more scripts!';
            OFHelper.sendMessageToPlugin(pluginName, response);
            return;
          }
          OFHelper.sendMessageToPlugin(pluginName, response);
        };
        loadScripts();
      }
    };

    this.operationHandlers[OPERATIONS.GET_USER_DETAILS] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        const xhr = new XMLHttpRequest();
        const profileID = localStorage.getItem('profileID');
        xhr.onload = (() => {
          const user = JSON.parse(xhr.responseText);
          let att = '';

          if (user.attributes !== '{}') {
            const attributeL = JSON.parse(user.attributes);
            const attribute = attributeL.attributes;

            for (const att of attribute) {
              if (att.type === 'secure string') {
                att.value = atob(att.value);
              }
            }

            att = JSON.stringify(attribute);
            if (att === '[]') {
              att = '';
            }
          }

          const userDetails: IUserDetails = {
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            username: user.username,
            attributes: att,
            profiles: user.profiles
          };

          const response: IResponse = {
            request: message,
            isResponse: true,
            reject: undefined,
            resolve: userDetails
          };
          OFHelper.sendMessageToPlugin(pluginName, response);
        }).bind(this);
        xhr.open('GET', `${this.apiUrl}/${this.apiVersion}/Api/Me/?profileID=${profileID}`);
        xhr.withCredentials = true;
        xhr.send();
      }
    };

    this.operationHandlers[OPERATIONS.SET_USER_ATTRIBUTES] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        const userId = message.data[0];
        let data = JSON.parse(message.data[1]);
        const xhr = new XMLHttpRequest();

        for (const att of data) {
          if (att.type === 'secure string') {
            att.value = btoa(att.value);
          }
        }

        data = JSON.stringify(data);

        xhr.onload = (() => {
          const result = xhr.responseText === 'Success' ? true : false;
          const response: IResponse = {
            request: message,
            isResponse: true,
            reject: undefined,
            resolve: result
          };
          OFHelper.sendMessageToPlugin(pluginName, response);
        }).bind(this);

        xhr.open('PUT', `${this.apiUrl}/${this.apiVersion}/Api/UserAttributes/${userId}`);

        xhr.withCredentials = true;
        xhr.setRequestHeader('content-type', 'application/json');

        xhr.send(data);
      }
    };

    this.operationHandlers[OPERATIONS.NOTIFICATION_TO_FRAMEWORK] = {
      operationHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        this.defaultOperationHandler(pluginName, operation, message);
        if (this.loginTimeoutService.inactivityTimer > 0) {
          this.loginTimeoutService.throttle(this.loginTimeoutService.resetInactivityTimer(), 500);
        }
        const currentValue: INotificationMessage[] = OFHelper.notificationMessages$.getValue();
        if (message.data != null && message.data.length !== 0 && message.data[0] !== '') {
          this.loggerService.logger.logDebug(`agent received NOTIFICATION_TO_FRAMEWORK event : "${JSON.stringify(message)}"`, ERROR_CODE.Other);
          const notification: INotificationMessage = {
            message: message.data[0],
            notificationType: message.data[1] ? message.data[1] : NOTIFICATION_TYPE.Information
          };

          if (notification.notificationType === NOTIFICATION_TYPE.Alert) {
            OFHelper.plugins[pluginName].addAlert();
          } else {
            OFHelper.notificationMessages$.next([notification]);
          }
        }
      },
      operationRegisterHandler: (pluginName: string, operation: OPERATIONS, message?: any) => {
        this.defaultRegisterOperationHandler(pluginName, operation, message);
      }
    };
  }

  /**
   * This sends events to the crm app/s to set the height of the toolbar
   */
  sendSetSoftphoneHeight() {
    if (OPERATIONS.SET_SOFTPHONE_HEIGHT in this.registeredOperations && this.registeredOperations[OPERATIONS.SET_SOFTPHONE_HEIGHT].length > 0) {
      const documentHeight = this.getSoftPhoneHeight();
      this.lastDocumentHeight = documentHeight ? documentHeight : this.lastDocumentHeight;
      if (documentHeight && this.shouldSendSetHeightEvents$.value) {
        this.loggerService.logger.logInformation(`sending change height notification to the crm app with height ${documentHeight}`, ERROR_CODE.Other);
        const newMessage: IRequest = {
          operation: OPERATIONS.SET_SOFTPHONE_HEIGHT,
          data: [documentHeight]
        };
        // eslint-disable-next-line guard-for-in
        for (const i in this.registeredOperations[OPERATIONS.SET_SOFTPHONE_HEIGHT]) {
          const targetPlugin = this.registeredOperations[OPERATIONS.SET_SOFTPHONE_HEIGHT][i];
          OFHelper.sendMessageToPlugin(targetPlugin, newMessage);
        }
      }
    }
  }

  @bind
  static sendMessageToPlugin(pluginName: string, message: IRequest | IResponse) {
    OFHelper.plugins[pluginName].window.postMessage(JSON.stringify(message), '*');
  }

  /**
   * This is a version of sendMessageToPlugin that will first send the event to any registered interceptors.
   * It is used when an operation is handled or initiated by the softphone instead of an app.
   * @param pluginName
   * @param message
   */
  @bind
  async sendMessageToPluginWithInterceptors(pluginName: string, message: IRequest | IResponse) {
    // Run interceptors on event (if any) before sending to registered operation handlers
    try {
      const operation = isResponse(message) ? message.request.operation : message.operation;

      const updatedEvent = await this.runInterceptors(operation, {
        pluginName,
        message
      });
      pluginName = updatedEvent.pluginName;
      message = updatedEvent.message;

      OFHelper.sendMessageToPlugin(pluginName, message);
    } catch (e) {
      // One of the interceptors rejected. This means the operation should be canceled.
      const operationNumber = isResponse(message) ? message.request.operation : message.operation;
      let operation;
      if (operationNumber !== null && operationNumber !== undefined) {
        operation = OPERATIONS[operationNumber];
      }
      const id = isResponse(message) ? message.request.operation : message.id;
      this.loggerService.logger.logDebug(
        'Operation canceled by interceptor. canceledBy=' +
          pluginName +
          '; operation =' +
          operation +
          '; id= ' +
          id +
          '; returned rejection =' +
          JSON.stringify(e)
      );
    }
  }

  // Copied and repurposed from getSoftPhoneHeight. Calculates maximum width
  // across all layers, determines appropriate width to set softphone
  @bind
  getSoftPhoneWidth() {
    try {
      if (this.globalConfiguration.WidthType === 'Fixed') {
        return this.globalConfiguration.Width;
      }
      const padding = 0;
      // Verify softphone window width and screen avail width to determine the best setting
      const layers = document.querySelectorAll('.app-layer');
      let maxLayerWidth = 0;
      layers.forEach((el: HTMLElement) => {
        let layerWidth = 0;
        for (let i = 0; i < el.children.length; i++) {
          layerWidth += (el.children.item(i) as HTMLElement).scrollWidth;
        }
        if (layerWidth > maxLayerWidth) {
          maxLayerWidth = layerWidth;
        }
      });
      const documentWidth = maxLayerWidth;
      const windowWidth = $(window).width();
      const minWidth = this.globalConfiguration.Width;

      // Consider which ever is wider
      const requiredWidth = documentWidth < minWidth ? minWidth : documentWidth;

      let finalWidth = minWidth;
      let availScreenWidth = 0;
      let minWidthAdjustFactor = 0;

      // Consider a MAX of 80% of screen size
      availScreenWidth = (window.screen.availWidth * 80) / 100;
      if (requiredWidth > availScreenWidth) {
        finalWidth = availScreenWidth;
      } else {
        finalWidth = requiredWidth;
      }

      if (windowWidth > availScreenWidth) {
        minWidthAdjustFactor = 0; // In Console mode window Width should never be more than availscreenWidth
      } else {
        minWidthAdjustFactor = availScreenWidth / 4; // Minimum of 1/4 th change in Width
      }

      let changeWidth = false;
      // If WindowWidth is greater than the required Width but it is within the minWidthAdjustFactor, do not reduce the Width

      if (windowWidth + padding > finalWidth) {
        // Let window reduce it's size only if minWidthAdjustFactor is satisfied
        const currentChangeFactor = windowWidth + padding - finalWidth;
        if (currentChangeFactor > minWidthAdjustFactor) {
          changeWidth = true;
        }
      } else if (windowWidth + padding < finalWidth) {
        // Increase the Width as needed
        changeWidth = true;
      }

      return changeWidth ? finalWidth + padding : null;
    } catch (e) {
      this.loggerService.logger.logError('Error in getSoftPhoneWidth! Error=' + e);
    }
  }

  @bind
  getSoftPhoneHeight() {
    try {
      if (this.globalConfiguration.HeightType === 'Fixed') {
        return this.globalConfiguration.Height;
      } else {
        // Verify softphone window Height and screen avail height to determine the best setting
        const layers = document.querySelectorAll('.app-layer');
        let maxLayerHeight = 0;
        layers.forEach((el: HTMLElement) => {
          let thisLayerHeight = 0;
          for (let i = 0; i < el.children.length; i++) {
            if (!el.children.item(i).classList.contains('splitter')) {
              thisLayerHeight += el.children.item(i).scrollHeight;
            }
          }
          if (thisLayerHeight > maxLayerHeight) {
            maxLayerHeight = thisLayerHeight;
          }
        });
        const documentHeight = maxLayerHeight;
        const windowHeight = $(window).height();
        const minHeight = this.globalConfiguration.Height;

        // Consider which ever is higher
        const requiredHeight = documentHeight < minHeight ? minHeight : documentHeight;

        let finalHeight = minHeight;
        let availScreenHeight = 0;
        let minHeightAdjustFactor = 0;

        // Consider a MAX of 80% of screen size
        availScreenHeight = (window.screen.availHeight * 80) / 100;
        if (requiredHeight > availScreenHeight) {
          finalHeight = availScreenHeight;
        } else {
          finalHeight = requiredHeight;
        }

        if (windowHeight > availScreenHeight) {
          minHeightAdjustFactor = 0; // In Console mode window height should never be more than availscreenheight
        } else {
          minHeightAdjustFactor = availScreenHeight / 4; // Minimum of 1/4 th change in height
        }

        let changeHeight = false;
        // If WindowHeight is greater than the required height but it is within the minHeightAdjustFactor, do not reduce the height

        if (windowHeight > finalHeight) {
          // Let window reduce it's size only if minHeightAdjustFactor is satisfied
          const currentChangeFactor = windowHeight - finalHeight;
          if (currentChangeFactor > minHeightAdjustFactor) {
            changeHeight = true;
          }
        } else if (windowHeight < finalHeight) {
          // Increase the height as needed
          changeHeight = true;
        }

        if (changeHeight) {
          return finalHeight;
        }
      }
    } catch (e) {
      this.loggerService.logger.logError('Error in getSoftPhoneHeight! Error=' + e);
    }
    return null;
  }

  @bind
  loadPlugins(onlyLoadGlobalPlugins: boolean = false, apiUrl: string, apiVersion: string, profileID?: string) {
    const profile: Profile = new Profile(apiUrl, apiVersion);
    // eslint-disable-next-line max-statements
    return profile.GetProfileAsync(profileID).then((pluginConfigs: any) => {
      this.pluginConfigs$.next(pluginConfigs);
      const configPlugins = pluginConfigs.filter((plugin) => plugin.variables.PluginType && plugin.variables.PluginType === 'Configuration');
      const otherPlugins = pluginConfigs.filter((plugin) => !(plugin.variables.PluginType && plugin.variables.PluginType === 'Configuration'));

      // Setup global/framework configuration
      const globalConfiguration: IGlobalConfiguration = {
        ...defaultGlobalConfiguration
      };

      const loggerConfiguration: ILoggerConfiguration = {
        ...defaultLoggerConfiguration
      };
      for (const configPlugin of configPlugins) {
        // There should only be one app tile in Studio for the
        // DaVinci Agent config. However, Because the logic surrounding it
        // does allow for multiple 'Configuration' type app tiles,
        // the below logic will make sure that all agent configurations
        // are captured in a single object, just in case there ever are
        // multiple 'Configuration' apps.
        this.davinciAgentConfig = {
          ...this.davinciAgentConfig,
          ...configPlugin['Logger Options']
        };

        if (configPlugin.Configuration !== null && configPlugin.Configuration.variables !== null) {
          for (const variableName of Object.keys(configPlugin.Configuration.variables)) {
            if (globalConfiguration[variableName]) {
              globalConfiguration[variableName] = configPlugin.Configuration.variables[variableName];
            }
          }
        }
      }

      // Read and initialize interaction cleanup configuration
      if (
        configPlugins[0] &&
        configPlugins[0]['Configuration'] &&
        configPlugins[0]['Configuration']['variables'] &&
        configPlugins[0]['Configuration']['variables']['Interaction Cleanup Frequency']
      ) {
        this.interactionCleanupFrequency = configPlugins[0]['Configuration']['variables']['Interaction Cleanup Frequency'];
      }

      if (
        configPlugins[0] &&
        configPlugins[0]['Configuration'] &&
        configPlugins[0]['Configuration']['variables'] &&
        configPlugins[0]['Configuration']['variables']['Interaction Expiration Time']
      ) {
        this.interactionExpirationTime = configPlugins[0]['Configuration']['variables']['Interaction Expiration Time'];
      }

      if (!this.interactionCleanupFrequency || this.interactionCleanupFrequency < 1) {
        this.interactionCleanupFrequency = defaultGlobalConfiguration.InteractionCleanupFrequency;
      }

      if (!this.interactionExpirationTime || this.interactionExpirationTime < 1) {
        this.interactionExpirationTime = defaultGlobalConfiguration.InteractionExpirationTime;
      }

      // Begin interaction cleanup interval
      setInterval(() => {
        this.dataService.cleanupInteractions(this.interactionExpirationTime);
      }, this.interactionCleanupFrequency);

      // Read logger configs and initialize logger service
      let logLevel = parseInt(this.davinciAgentConfig?.variables?.['Log Level']?.toString(), 10);
      logLevel = isNaN(logLevel) ? defaultLoggerConfiguration['Log Level'] : logLevel;

      let maxLength = parseInt(this.davinciAgentConfig?.['Console Logger']?.variables?.['Max Length']?.toString(), 10);
      maxLength = isNaN(maxLength) ? defaultLoggerConfiguration['Console Logger']['Max Length'] : maxLength;

      loggerConfiguration['Log Level'] = logLevel;
      loggerConfiguration['Logger Type'] = this.davinciAgentConfig?.variables?.['Logger Type']?.toString() || defaultLoggerConfiguration['Logger Type'];
      loggerConfiguration['Premise Logger URL'] =
        this.davinciAgentConfig?.variables?.['Premise Logger URL']?.toString() || defaultLoggerConfiguration['Premise Logger URL'];
      loggerConfiguration['Console Logger']['Max Length'] = maxLength;

      this.loggerService.initialize(loggerConfiguration);

      this.globalConfiguration$.next(globalConfiguration);
      try {
        if (configPlugins[0] && configPlugins[0]['Interceptor Sequence'] && configPlugins[0]['Interceptor Sequence']['variables']) {
          const unsortedInterceptorConfig = configPlugins[0]['Interceptor Sequence']['variables'];
          this.interceptorConfig = {};

          this.processInterceptorSequenceConfig(unsortedInterceptorConfig);
        }
      } catch (e) {
        this.loggerService.logger.logError('OFHelper: Error in loadPlugins callback. Error: ' + JSON.stringify(e));
        this.interceptorConfig = {};
      }

      // Load apps
      for (const pluginConfig of otherPlugins) {
        try {
          if (onlyLoadGlobalPlugins && pluginConfig.variables.PluginType === 'globalControl') {
            if (pluginConfig.variables.URL.indexOf('?') > -1) {
              pluginConfig.variables.URL = pluginConfig.variables.URL + '&disabled=true';
            } else {
              pluginConfig.variables.URL = pluginConfig.variables.URL + '?disabled=true';
            }
          }
        } catch (e) {
          // TODO: log error
          console.error(e.message);
        }
        const plugin = new DaVinciApp(pluginConfig);
        OFHelper.plugins[plugin.name] = plugin;
      }
      console.log(`this.finishInitializingPlugins ${this.finishInitializingPlugins}`);
      this.loginTimeoutService.inactivityTimer = globalConfiguration.InactivityTimer * 60000;
      if (this.loginTimeoutService.inactivityTimer > 0) {
        this.loginTimeoutService.initialize();
        this.loginTimeoutService.startInactivityTimer();
      }
    });
  }

  processInterceptorSequenceConfig(unsortedInterceptorConfig) {
    try {
      // Sort lists
      const eventsInInterceptorConfig = Object.keys(unsortedInterceptorConfig);
      for (let i = 0; i < eventsInInterceptorConfig.length; i++) {
        // Create an entry in this.interceptorConfig for the current event
        this.interceptorConfig[eventsInInterceptorConfig[i]] = [];
        const mapForSpecificEvent = unsortedInterceptorConfig[eventsInInterceptorConfig[i]];

        const orderingKeysForSpecificEvent = Object.keys(mapForSpecificEvent);
        for (let j = 0; j < orderingKeysForSpecificEvent.length; j++) {
          this.interceptorConfig[eventsInInterceptorConfig[i]].push(mapForSpecificEvent[orderingKeysForSpecificEvent[j]]);
        }
      }
    } catch (e) {
      this.loggerService.logger.logError('OFHelper: Error while processing Interceptor Sequence Configuration. Error: ' + JSON.stringify(e));
      this.interceptorConfig = {};
    }
  }

  @bind
  finishInitializingPlugins() {
    if (OFHelper.finishedInitializingPlugins === false) {
      OFHelper.finishedInitializingPlugins = true;
      this.appsLoaded.next(true);
      window.setTimeout(() => {
        this.loggerService.logger.logDebug(
          `invoked setTimeOut event after ${this.globalConfiguration.dimensionChangeWaitTime} seconds after initialize complete`,
          ERROR_CODE.Other
        );
        this.shouldSendSetHeightEvents$.next(true);
      }, this.globalConfiguration.dimensionChangeWaitTime * 1000);
      while (this.queuedEvents.length > 0) {
        this.postMessageListener(this.queuedEvents.shift());
      }
      setTimeout(() => $('#body-ModalProgressDivId').hide(), 100);
    }
  }

  @bind
  checkIfLastPluginToInitialize() {
    let finishedInitializing = true;
    for (const plugin of Object.values(OFHelper.plugins)) {
      if (!plugin.initializationCompleted) {
        finishedInitializing = false;
        break;
      }
    }

    if (finishedInitializing) {
      this.finishInitializingPlugins();
    }
  }

  async handleIsResponse(pluginName, data) {
    // If callback is defined use that instead of normal response handlers
    if (this.requestedOperations[data.request.id] != null && this.requestedOperations[data.request.id].callback != null) {
      this.operationResponseHandlerForCallbacks(pluginName, data.request.operation, data);
    } else {
      // Run interceptors on event (if any) before sending to registered operation handlers
      try {
        const updatedEvent = await this.runInterceptors(data.request.operation, {
          pluginName,
          message: data
        });
        pluginName = updatedEvent.pluginName;
        data = updatedEvent.message;
      } catch (e) {
        // One of the interceptors rejected. This means the operation should be canceled.
        let operation;
        let id;
        if (data.isResponse && data.request && data.request.operation) {
          operation = data.request.operation;
          if (operation !== null && operation !== undefined) {
            operation = OPERATIONS[operation];
          }
        }
        if (data.isResponse && data.request && data.request.id) {
          id = data.request.id;
        }
        this.loggerService.logger.logDebug(
          'Operation canceled by interceptor. canceledBy=' +
            pluginName +
            '; operation=' +
            JSON.stringify(operation) +
            '; requestId = ' +
            id +
            '; returned rejection =' +
            JSON.stringify(e)
        );

        data.reject = 'Operation canceled by interceptor';
      }

      if (data.request.operation in this.operationHandlers && 'operationResponseHandler' in this.operationHandlers[data.request.operation]) {
        this.operationHandlers[data.request.operation].operationResponseHandler(pluginName, data.request.operation, data);
      } else {
        this.defaultOperationResponseHandler(pluginName, data.request.operation, data);
      }
    }
  }

  @bind
  async postMessageListener(event: any) {
    const pluginName = this.getPluginNameFromEvent(event);
    const data = Helper.safeJSONParse(event.data);

    if (pluginName !== null && pluginName in OFHelper.plugins && data !== null) {
      if (data.isResponse) {
        this.handleIsResponse(pluginName, data);
      } else if (data.operation === OPERATIONS.INTERACTION || data.operation === OPERATIONS.UPDATE_INTERACTION) {
        if (OFHelper.finishedInitializingPlugins || this.validOperationsBeforeLoad.includes(data.operation)) {
          // These are operations (currently just INTERACTION) which have Q support
          this.queueService.enqueueInteraction(data, pluginName, this.interactionQueueCallback);
        } else {
          // Queue events until all plugins finish loading(or a timeout occurs)
          this.queuedEvents.push(event);
        }
      } else if (data.operation in OPERATIONS) {
        // These are operations which do not have Q support yet
        if (OFHelper.finishedInitializingPlugins || this.validOperationsBeforeLoad.includes(data.operation)) {
          // Run interceptors on event (if any) before sending to registered operation handlers
          const updatedData = await this.performInterceptor(data, pluginName);
          if (updatedData) {
            this.performOperation(updatedData, pluginName);
          } else {
            return;
          }
        } else {
          // Queue events until all plugins finish loading(or a timeout occurs)
          this.queuedEvents.push(event);
        }
      } else if (data.registerOperation in OPERATIONS) {
        if (data.registerOperation in this.operationHandlers && 'operationRegisterHandler' in this.operationHandlers[data.registerOperation]) {
          this.operationHandlers[data.registerOperation].operationRegisterHandler(pluginName, data.registerOperation, data);
        } else {
          this.defaultRegisterOperationHandler(pluginName, data.registerOperation, data);
        }
      }
    }
  }

  /**
   * Callback passed to queue service to handle interactions.
   * This function has three scenarios that it handles:
   * 1. The interaction can be intercepted and not updated or it is not intercepted.
   * 2. The interaction is intercepted and updated.
   * 3. The interaction is intercepted and canceled.
   *
   * @param {*} data
   * @param {string} pluginName
   * @return {*}  {Promise<void>}
   * @memberof OFHelper
   */
  @bind
  async interactionQueueCallback(data: any, pluginName: string): Promise<void> {
    const functionName = 'interactionQueueCallback';
    try {
      this.loggerService.logger.logTrace(
        `${functionName} | Received interaction from queue. interactionId: ${data?.data[0]?.interactionId}, dataId: ${data?.id}, pluginName: ${pluginName}`
      );
      const updatedData = await this.performInterceptor(data, pluginName);
      if (updatedData) {
        this.performOperation(updatedData, pluginName);
      } else {
        this.loggerService.logger.logTrace(
          // eslint-disable-next-line max-len
          `${functionName} | Interaction was cancelled by interceptor. interactionId: ${data?.data[0]?.interactionId}, dataId: ${data?.id}, pluginName: ${pluginName}`
        );
      }
      return Promise.resolve();
    } catch (e) {
      this.loggerService.logger.logError(`OFHelper | ${functionName} | Error: ${JSON.stringify(e)}`);
      return Promise.reject(e);
    }
  }

  async performInterceptor(data: any, pluginName: string): Promise<any> {
    const functionName = 'performInterceptor';
    try {
      this.loggerService.logger.logDebug(`${functionName} | Sending event to interceptors. dataId: ${data.id}, pluginName: ${pluginName}`);
      const updatedEvent = await this.runInterceptors(data.operation, {
        pluginName,
        message: data
      });
      if (updatedEvent) {
        pluginName = updatedEvent.pluginName;
        data = updatedEvent.message;
        return data;
      } else {
        // One of the interceptors rejected or there was an error in the interceptor. This means the operation should be canceled.
        this.loggerService.logger.logDebug(
          `Operation canceled by interceptor. canceledBy=${pluginName}; operation=${
            data?.request?.operation != null ? JSON.stringify(OPERATIONS[data?.request?.operation]) : ''
          }; request id ${data?.request?.id}}`
        );

        OFHelper.sendMessageToPlugin(pluginName, {
          request: data,
          isResponse: true,
          reject: 'Canceled by interceptor',
          resolve: undefined
        });
      }
    } catch (e) {
      this.loggerService.logger.logError(`OFHelper | ${functionName} | Error: ${JSON.stringify(e)}`);
      return Promise.reject(e);
    }
  }

  async performOperation(data: any, pluginName: string) {
    const functionName = 'performOperation';
    try {
      this.loggerService.logger.logDebug(`${functionName} | Performing operations for event. dataId: ${data.id}, pluginName: ${pluginName}`);
      if (data.operation in this.operationHandlers && 'operationHandler' in this.operationHandlers[data.operation]) {
        this.operationHandlers[data.operation].operationHandler(pluginName, data.operation, data);
      } else {
        this.defaultOperationHandler(pluginName, data.operation, data);
      }
    } catch (e) {
      this.loggerService.logger.logError('OFHelper: Error while performing operation. Error: ' + JSON.stringify(e));
    }
  }

  /**
   * This sends an intercept operation
   * It will resolve when the app sends a response. Will reject if the app sends a rejection.
   * If a timeout occurs it will either:
   *  - reject (INTERCEPTOR_TIMEOUT_ACTION.CANCEL)
   *  - or resolve w/ initial event (INTERCEPTOR_TIMEOUT_ACTION.PROCEED_WITH_ORIGINAL)
   * This bypasses the normal response handlers(defaultOperationResponseHandler or the one specified in operationHandlers).
   * @param interceptor
   * @param request
   * @returns The contents of IResponse.resolve
   */
  sendInterceptRequest(
    interceptor: {
      pluginName: string;
      timeoutInMilliseconds: number;
      timeoutAction: INTERCEPTOR_TIMEOUT_ACTION;
    },
    event: {
      pluginName: string;
      message: IRequest | IResponse;
    }
  ): Promise<{
    pluginName: string;
    message: IRequest | IResponse;
  }> {
    return new Promise((resolve, reject) => {
      const request = {
        operation: OPERATIONS.INTERCEPT,
        data: [event],
        id: getSequenceID()
      };
      this.requestedOperations[request.id] = {
        originPluginName: interceptor.pluginName,
        timeout: window.setTimeout(() => {
          // If no response, log error and remove this from request list
          this.loggerService.logger.logWarning(
            'Interceptor failed to respond before timeout! interceptorPlugin=' +
              interceptor.pluginName +
              '; timeout action= ' +
              JSON.stringify(interceptor.timeoutAction) +
              '; operation = ' +
              OPERATIONS[request.operation] +
              '; request id= ' +
              request.id
          );

          delete this.requestedOperations[request.id];
          switch (interceptor.timeoutAction) {
            case INTERCEPTOR_TIMEOUT_ACTION.PROCEED_WITH_ORIGINAL:
              resolve(event);
              break;
            case INTERCEPTOR_TIMEOUT_ACTION.CANCEL:
            default:
              reject('Timeout');
          }

          // send timeout message
          OFHelper.sendMessageToPlugin(interceptor.pluginName, {
            operation: OPERATIONS.INTERCEPT,
            data: [{ ...event, didTimeout: true }],
            id: getSequenceID()
          });
        }, interceptor.timeoutInMilliseconds || 1 * 60 * 1000),
        callback: (respondingPlugin: string, operation: OPERATIONS, response: IResponse) => {
          if (response.reject != null) {
            reject(response.reject);
          } else if (response.resolve != null) {
            resolve(response.resolve);
          } else {
            reject('Interceptor response did not contain resolve or reject!');
          }
        }
      };

      OFHelper.sendMessageToPlugin(interceptor.pluginName, request);
    });
  }

  /**
   * This runs any interceptors registered for the given operation.
   * Interceptors are run sequentially in the order they registered(if there is more then one).
   * Interceptors can modify or cancel an event/operation.
   * Each one is required to return the event, and the returned version will then be used instead of the original.
   * If a single interceptor rejects then this function will immediately reject without running additional interceptors.
   * A rejection signals that the event/operation should be canceled.
   * @param operation The type of operation to be intercept
   * @param event The event to be intercepted
   * @returns The (possibly) modified event.
   */
  async runInterceptors(
    operation: OPERATIONS,
    event: {
      pluginName: string;
      message: IRequest | IResponse;
    }
  ): Promise<{
    pluginName: string;
    message: IRequest | IResponse;
  }> {
    if (this.registeredInterceptors[operation] != null && this.registeredInterceptors[operation].length > 0) {
      const interceptors = this.registeredInterceptors[operation];

      for (const interceptor of interceptors) {
        let id;
        try {
          id = isResponse(event.message) ? event.message.request.id : event.message.id;
        } catch (e) {
          this.loggerService.logger.logError('OFHelper: Error while retrieving id of intercept request: ' + JSON.stringify(e));
        }
        this.loggerService.logger.logDebug(
          'runInterceptors: About to send intercept request. Plugin name: ' +
            interceptor.pluginName +
            '; timeout action= ' +
            JSON.stringify(interceptor.timeoutAction) +
            '; operation = ' +
            OPERATIONS[operation] +
            '; request id= ' +
            id
        );
        event = await this.sendInterceptRequest(interceptor, event);
        this.loggerService.logger.logDebug(
          'runInterceptors: Returned from sendInterceptRequest. Plugin name: ' +
            interceptor.pluginName +
            '; timeout action= ' +
            JSON.stringify(interceptor.timeoutAction) +
            '; operation = ' +
            OPERATIONS[operation] +
            '; request id= ' +
            id
        );
      }
    }
    return event;
  }

  @bind
  getPluginNameFromEvent(event: MessageEvent) {
    try {
      for (const pluginName in OFHelper.plugins) {
        if (OFHelper.plugins[pluginName].iframe.contentWindow === event.source) {
          return pluginName;
        }
      }
    } catch (e) {
      this.loggerService.logger.logError('Error in getPluginNameFromEvent! Error=' + e);
    }
    return null;
  }

  @bind
  defaultOperationHandler(originPlugin: string, operation: OPERATIONS, data?: any) {
    try {
      if (!data.id) {
        throw new Error('No id defined for request!');
      }

      if (!(operation in this.registeredOperations && this.registeredOperations[operation].length > 0)) {
        throw new Error('No plugin registered for that event!');
      }

      this.requestedOperations[data.id] = {
        originPluginName: originPlugin,
        timeout: window.setTimeout(() => {
          // If no response, log error and remove this from request list
          this.loggerService.logger.logError(
            'Failed to receive response for request! originPlugin=' + originPlugin + '; operation=' + operation + '; data=' + JSON.stringify(data)
          );
          delete this.requestedOperations[data.id];
        }, 1 * 60 * 1000)
      };

      if (operation in this.registeredOperations && this.registeredOperations[operation].length > 0) {
        // eslint-disable-next-line guard-for-in
        for (const i in this.registeredOperations[operation]) {
          const targetPlugin = this.registeredOperations[operation][i];
          OFHelper.sendMessageToPlugin(targetPlugin, data);
        }
      }
    } catch (e) {
      this.loggerService.logger.logError(
        'Error in defaultOperationHandler. originPlugin=' + originPlugin + '; operation=' + operation + '; data=' + JSON.stringify(data) + '; Error=' + e
      );
      const response: IResponse = {
        request: data,
        isResponse: true,
        reject: e,
        resolve: undefined
      };
      OFHelper.sendMessageToPlugin(originPlugin, response);
    }
  }

  @bind
  defaultOperationResponseHandler(respondingPlugin: string, operation: OPERATIONS, data?: any) {
    try {
      if (!this.requestedOperations[data.request.id]) {
        throw new Error(
          'No pending request found with id=' + data.request.id + '. The plugin most likely already sent a response or took to long to response.'
        );
      }
      window.clearTimeout(this.requestedOperations[data.request.id].timeout);
      OFHelper.sendMessageToPlugin(this.requestedOperations[data.request.id].originPluginName, data);
      delete this.requestedOperations[data.request.id];
    } catch (e) {
      this.loggerService.logger.logError(
        'Error in defaultOperationResponseHandler. respondingPlugin=' +
          respondingPlugin +
          '; operation=' +
          operation +
          '; data=' +
          JSON.stringify(data) +
          '; Error=' +
          e
      );
    }
  }

  /**
   * Operation response handler used if the object in requestedOperations includes a callback
   * for instance: if the request was added via sendRequestToPluginWaitForResponse
   * If a callback is defined then this will be used instead of the default response handler
   * or before any response handler that is defined in operationHandlers
   */
  @bind
  operationResponseHandlerForCallbacks(respondingPlugin: string, operation: OPERATIONS, data?: any) {
    try {
      if (!this.requestedOperations[data.request.id]) {
        throw new Error(
          'No pending request found with id=' + data.request.id + '. The plugin most likely already sent a response or took to long to response.'
        );
      }
      window.clearTimeout(this.requestedOperations[data.request.id].timeout);
      this.requestedOperations[data.request.id].callback(respondingPlugin, operation, data);
      delete this.requestedOperations[data.request.id];
    } catch (e) {
      this.loggerService.logger.logError(
        'Error in defaultOperationResponseHandler. respondingPlugin=' +
          respondingPlugin +
          '; operation=' +
          operation +
          '; data=' +
          JSON.stringify(data) +
          '; Error=' +
          e
      );
    }
  }

  @bind
  defaultRegisterOperationHandler(pluginName: string, operation: OPERATIONS, registerRequest: any) {
    const fname = 'defaultRegisterOperationHandler()';

    this.loggerService.logger.logInformation(`OFHelper - ${fname} - ${JSON.stringify(pluginName)} registered for ${JSON.stringify(OPERATIONS[operation])}`);

    if (this.registeredOperations[operation]) {
      for (const name of this.registeredOperations[operation]) {
        if (name === pluginName) {
          return;
        }
      }
      this.registeredOperations[operation].push(pluginName);
    } else {
      this.registeredOperations[operation] = [pluginName];
    }

    const response: IResponse = {
      request: registerRequest,
      isResponse: true,
      reject: undefined,
      resolve: undefined
    };
    this.registeredOperations$.next(this.registeredOperations);
    OFHelper.sendMessageToPlugin(pluginName, response);
  }

  @bind
  broadcastMessageOperationHandler(originPlugin: string, operation: OPERATIONS, data?: any) {
    try {
      if (operation in this.registeredOperations && this.registeredOperations[operation].length > 0) {
        // eslint-disable-next-line guard-for-in
        for (const i in this.registeredOperations[operation]) {
          const targetPlugin = this.registeredOperations[operation][i];
          OFHelper.sendMessageToPlugin(targetPlugin, data);
        }
      }

      // Send response saying that this event was successfully broadcast to other plugins
      const response: IResponse = {
        request: data,
        isResponse: true,
        reject: undefined,
        resolve: undefined
      };
      OFHelper.sendMessageToPlugin(originPlugin, response);
    } catch (e) {
      this.loggerService.logger.logError(
        'Error in broadcastMessageOperationHandler. originPlugin=' +
          originPlugin +
          '; operation=' +
          operation +
          '; data=' +
          JSON.stringify(data) +
          '; Error=' +
          e
      );
      const response: IResponse = {
        request: data,
        isResponse: true,
        reject: e,
        resolve: undefined
      };
      OFHelper.sendMessageToPlugin(originPlugin, response);
    }
  }

  @bind
  createCacheRequestOperationHandler(operationHandler: (originPlugin: string, operation: OPERATIONS, request?: any) => void) {
    return (originPlugin: string, operation: OPERATIONS, request?: any) => {
      this.cachedRequests[operation] = request;
      operationHandler(originPlugin, operation, request);
    };
  }

  createSendCachedOpRegisterHandler(operationRegisterHandler: (pluginName: string, operation: OPERATIONS, registerRequest: any) => void) {
    return (pluginName: string, operation: OPERATIONS, registerRequest: any) => {
      operationRegisterHandler(pluginName, operation, registerRequest);
      if (this.cachedRequests[operation] && OFHelper.plugins[pluginName].initializationCompleted) {
        OFHelper.sendMessageToPlugin(pluginName, this.cachedRequests[operation]);
      }
    };
  }

  @bind
  sendAllCachedRequestForPlugin(pluginName: string) {
    // eslint-disable-next-line guard-for-in
    for (const operation in this.registeredOperations) {
      for (const plugin of this.registeredOperations[operation]) {
        if (plugin === pluginName) {
          if (this.cachedRequests[operation]) {
            OFHelper.sendMessageToPlugin(pluginName, this.cachedRequests[operation]);
          }
          break;
        }
      }
    }
  }

  @bind
  sendLoginToPlugin(pluginName: string) {
    const request: IRequest = {
      operation: OPERATIONS.LOGIN,
      data: null
    };
    OFHelper.sendMessageToPlugin(pluginName, request);
  }

  /**
   * This checks each app to find the one with the highest layer that is set to gray out layers beneath it.
   * If a change is detected it will emit layerToGrayOutBelow$
   * This will cause any layers below the given layer to be grayed out
   */
  updateLayerToGrayOut() {
    let maxLayer = 0;
    const apps = document.querySelectorAll('iframe[GrayOutLayersBelow=true]');
    for (let i = 0; i < apps.length; i++) {
      const layer = Number.parseInt(apps[i].getAttribute('AppLayer'), 10);
      if (!Number.isNaN(layer) && layer > maxLayer) {
        maxLayer = layer;
      }
    }
    if (maxLayer !== this.layerToGrayOutBelow) {
      this.layerToGrayOutBelow = maxLayer;
      this.layerToGrayOutBelow$.next(maxLayer);
    }
  }

  private filterByGroupName(contact: IContextualContact) {
    return (
      !this.globalConfiguration ||
      this.globalConfiguration.LivePresenceGroupNameFilter.length === 0 ||
      !contact.groupName ||
      this.globalConfiguration.LivePresenceGroupNameFilter.includes(contact.groupName)
    );
  }

  /**
   * This parses the contacts from the rest api and correctly formats them
   * @param contacts The contacts as returned from the rest api
   */
  private parseContacts(contacts: any[]): IContextualContact[] {
    try {
      return (contacts || []).map((contact) => {
        contact.channels = Object.values<IContextualContactChannel[]>(contact.channels || [])
          .reduce((a, b) => [...a, ...b], [])
          .map((channel) => {
            channel.channelType = parseInt(<any>channel.channelType, 10);
            return channel;
          });
        return contact;
      });
    } catch (error) {
      console.log(error);
    }
  }

  private filterByPresence(contact: IContextualContact) {
    return (
      !this.globalConfiguration ||
      this.globalConfiguration.LivePresenceFilterOnPresence.length === 0 ||
      this.globalConfiguration.LivePresenceFilterOnPresence.includes(contact.presence)
    );
  }

  private filterByApp(contact: IContextualContact) {
    if (!this.globalConfiguration || this.globalConfiguration.LivePresenceFilterOnApp.length === 0) {
      return true;
    }
  }

  private filterByChannelType(contact: IContextualContact) {
    if (this.channelTypeFilter.length === 0) {
      return true;
    }

    // filter out channels
    contact.channels = contact.channels.filter((channel) => this.channelTypeFilter.includes(channel.channelType));

    return contact.channels.length > 0;
  }

  private filterByChannelPresence(contact: IContextualContact) {
    // filter out channels based on their configured presence
    contact.channels = contact.channels.filter(
      (channel) =>
        channel.validPresences == null || channel.validPresences.length === 0 || contact.presence == null || channel.validPresences.includes(contact.presence)
    );

    return contact.channels.length > 0;
  }
}
